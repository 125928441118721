import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '@/views/Home'
//import MyEfreiConnection from '@/views/MyEfreiConnection'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: "welcome"
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/HomeView.vue'),
  },
  {
    path: '/connect',
    name: 'connect',
    component: () => import(/* webpackChunkName: "connect" */ '../views/MyEfreiConnection.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: () => import(/* webpackChunkName: "calculator" */ '../views/CalculatorView.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isAlreadyLogged(){
    return localStorage.getItem("access_token") != null
}

router.beforeEach((to, from, next) => {

    if(to.matched.some(record => record.meta.requiresAuth)){
      
      
        if(isAlreadyLogged()){
          next()
        }

        else {
          next({name: "connect"})
        }

    }

    else if(to.matched.some(record => record.meta.guest)){

      if(isAlreadyLogged()){
        next({name: 'calculator'})
      } 
      
      else {
        next()
      }

    } 
    
    else {
      next()
    }


})

export default router
