import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import Axios from 'axios'

Vue.config.productionTip = false

// Create a new Axios instance
const axiosApi = Axios.create({
  baseURL: 'https://api.myefrei.0x7.fr'
})

// If response is 401, redirect to login
axiosApi.interceptors.response.use(function (response) {
    return response
  }, 
  function (error) {
    if (error.response && error.response.status === 401) {
      // Refresh token
      return new Promise((resolve, reject) => {
        axiosApi.post('/efrei/oauth/refresh', `refresh_token=${localStorage.getItem("refresh_token")}`, {
          headers: {
            "Content-Type" : "application/x-www-form-urlencoded"
          }}).then((response) => {
            localStorage.setItem('access_token', response.data.access_token)
            localStorage.setItem('refresh_token', response.data.refresh_token)
          // Retry request
          error.config.headers['X-Appscho-Token'] = localStorage.getItem('access_token')
          Axios.request(error.config).then(response => { resolve(response) }).catch(error => { reject(error) })
        }).catch((error) => {
          // Redirect to login
          console.log(error)
          router.push('/connect')
          reject(error)
        })
      })
    }
  }
)

Vue.prototype.$http = axiosApi

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
