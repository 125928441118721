<template>
  <v-app>
    <v-app-bar
      app
      dark
      color="#0e3b5a"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo-myefrei-calculator-white.png"
          transition="scale-transition"
          width="150"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <v-icon>mdi-github</v-icon>
        <span class="mr-2 ml-1">Contribuer sur Github</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
//import Home from '@/views/Home';

export default {
  name: 'App',

  components: {
    
  },

  data: () => ({
    //
  }),
};
</script>
